import React from 'react'
import { Button, HStack } from '@chakra-ui/react'
import { useEventHandlersV2 } from '../../../../../hooks/useEventHandlers'
import { useNavigate } from 'react-router-dom'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { FileDownloadRounded, CoPresentRounded } from '../../../../atoms/Icon'
import { history } from '../../../../../../history'
import redirectToNextIfWindow from '../../../../../utils/redirectToNextIfWindow'
const Controls = () => {
	const { event } = useEventContext()
	const handlers = useEventHandlersV2(event.uuid)
	const navigate = useNavigate()
	return (
		<HStack align="center" spacing="1rem" w="full" mb="1rem">
			<Button
				onClick={() => redirectToNextIfWindow(handlers.public_root + `?v=${event.privateKey}`)}
				title="Present Now"
				variant="download"
				w="50%">
				<CoPresentRounded mr=".5rem" />
				Present Now
			</Button>
			<Button
				title="Click to download"
				icon="download"
				size="large"
				variant="download"
				w="50%"
				onClick={() => navigate(handlers.download)}>
				<FileDownloadRounded mr=".5rem" />
				Download
			</Button>
		</HStack>
	)
}
export default Controls
